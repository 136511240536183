export const environment = {
  production: false,
  apiUrl: 'https://api-tropico-de-escorpio.herokuapp.com',
  mediaUrl: 'https://tropico-de-escorpio.s3.us-east-2.amazonaws.com',
  ionicToken: 'z0ZbGH7HLEMU25MiuMxRu9t6e3FpLlujmXM2UbTN',
  stripeToken: 'pk_test_51HFWa3DOZpfO2kkqren1HKSggZSlMtF88mzym6ntncKN8fXgVwpfIbqDRh9Sh6vQ6kzmOQW1Fo3F9GP6Xj9UI0rR004x9Yrfzs',
  service: null
};

export const links = {
  youtube: 'https://www.youtube.com/channel/UCIAz24jRmVZqvsgH79oUVFw',
  facebook: 'https://www.facebook.com/tropicodescorpio'
};
