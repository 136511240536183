import { Injectable } from '@angular/core';
import { Autor } from '../interface/autor.interface';
import { LibrosService } from './libros.service';
import { Libro } from '../interface/libro.interface';

@Injectable({
  providedIn: 'root'
})
export class AutorService {
  // @ts-ignore
  autores = require('../../assets/json/autors.json') as Autor[];
  // @ts-ignore
  libros = require('../../assets/json/books.json') as Libro[];

  constructor() {}

  obetenerAutores(): Autor[] {
    return this.autores;
  }

  obtenerAutorPorId(id): Autor {
    const filter = this.autores.filter((o) => o.id === id);
    if (filter && filter.length) {
      return filter[0];
    } else {
      return null;
    }
  }

  librosDeAutor(id): Libro[] {
    const autor = this.obtenerAutorPorId(id);
    if (autor) {
      return this.libros.filter((o) => {
        if (typeof o.autor === 'number') {
          return o.autor === id;
        } else {
          return o.autor.id === id;
        }
      });
    } else {
      return [];
    }
  }

}
