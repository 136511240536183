import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserIService } from '../../../services/user-i.service';
import { LibrosService } from '../../../services/libros.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-libreria-libros',
  templateUrl: './libreria-libros.component.html',
  styleUrls: ['./libreria-libros.component.scss'],
})
export class LibreriaLibrosComponent implements OnInit {

  @Output() nuevaPagina = new EventEmitter();

  libros = [];

  paginas = 1;
  pagina = 1;
  nombre = null;

  buscando = true;
  busqueda = null;

  mediaUrl = environment.mediaUrl;

  constructor(
    private uiS: UserIService,
    private librosS: LibrosService,
    public router: Router,
    private actRouter: ActivatedRoute
  ) {
    this.paginas = this.librosS.pages;
  }

  ngOnInit() {
    this.nombre = this.actRouter.snapshot.paramMap.get('nombre');
    this.busqueda = this.nombre;
    if (this.busqueda) {
      this.libros = this.librosS.buscarLibros(this.busqueda, this.pagina);
      this.paginas = this.librosS.searchPages;
    } else {
      this.libros = this.librosS.obtenerLibros(this.pagina);
      this.paginas = this.librosS.pages;
    }
  }

  cargarPagina(suma) {
    this.buscando = true;
    this.libros = [];

    this.nuevaPagina.emit();

    this.pagina += suma;
    if (this.busqueda) {
      this.libros = this.librosS.buscarLibros(this.busqueda, this.pagina);
      this.paginas = this.librosS.searchPages;
    } else {
      this.libros = this.librosS.obtenerLibros(this.pagina);
      this.paginas = this.librosS.pages;
    }
  }

  verDetalle(o) {
    this.uiS.modalLibroDetalle(o);
  }

}
