import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-modal-politicas',
  templateUrl: './modal-politicas.component.html',
  styleUrls: ['./modal-politicas.component.scss'],
})
export class ModalPoliticasComponent implements OnInit, AfterViewInit {

  constructor(
    public modalC: ModalController,
    private utilities: UtilitiesService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const element = document.getElementById('modal-politicas');
    this.utilities.styleScrollbars(element);
  }

}
