import { Injectable } from '@angular/core';
import { PopoverController, ModalController, ToastController } from '@ionic/angular';
import { NavMenuComponent } from '../components/basic/nav-menu/nav-menu.component';
import { AutorDetalleComponent } from '../components/autor/autor-detalle/autor-detalle.component';
import { LibreriaDetalleComponent } from '../components/libreria/libreria-detalle/libreria-detalle.component';
import { ContactoComponent } from '../components/basic/contacto/contacto.component';
import { ModalPoliticasComponent } from '../components/basic/modal-politicas/modal-politicas.component';
import { LibreriaDemoComponent } from '../components/libreria/libreria-demo/libreria-demo.component';
import { LibreriaEbooksComponent } from '../components/libreria/libreria-ebooks/libreria-ebooks.component';
import { HomeNewsComponent } from '../components/home/home-news/home-news.component';

@Injectable({
  providedIn: 'root'
})
export class UserIService {

  constructor(
    private popC: PopoverController,
    private modalC: ModalController,
    private toastC: ToastController
  ) {}

  // ================================
  // Pop Overs
  // ================================

  // Menu para mostrar los ebooks disponibles
  // ================================
  async popEbooks(ev, ebooks: any[]) {
    const pop = await this.popC.create({
      component: LibreriaEbooksComponent,
      componentProps: {ebooks},
      mode: 'ios',
      event: ev,
      cssClass: 'pop-auto'
    });
    await pop.present();
  }

  // Menu de navegacion para diseño responsivo
  // ================================
  async popNavMenu(ev, page: string) {
    const pop = await this.popC.create({
      component: NavMenuComponent,
      componentProps: {page},
      mode: 'ios',
      event: ev,
      cssClass: 'pop-class'
    });
    await pop.present();
  }

  // ================================
  // Modals
  // ================================

  // Modal Good News
  // ================================
  async modalNews() {
    const modal = await this.modalC.create({
      component: HomeNewsComponent,
      cssClass: 'modal-class'
    });
    await modal.present();
  }

  // Modal para ver las politicas
  // ================================
  async modalPoliticas() {
    const modal = await this.modalC.create({
      component: ModalPoliticasComponent,
      cssClass: 'modal-class'
    });
    await modal.present();
  }

  // Modal para ver los detalles del autor
  // ================================
  async modalAutor(autor) {
    const modal = await this.modalC.create({
      component: AutorDetalleComponent,
      componentProps: {autor},
      cssClass: 'modal-autor'
    });
    await modal.present();
  }

  // Modal para ver detalles de un libro
  // ================================
  async modalLibroDetalle(libro) {
    const modal = await this.modalC.create({
      component: LibreriaDetalleComponent,
      componentProps: {libro},
      cssClass: 'modal-autor'
    });
    await modal.present();
  }

  // Modal para ver Contacto
  // ================================
  async modalContacto() {
    const modal = await this.modalC.create({
      component: ContactoComponent,
      cssClass: 'modal-class'
    });
    await modal.present();
  }

  // Modal para demo de Libro
  // ================================
  async modalDemo(demo, title) {
    const modal = await this.modalC.create({
      component: LibreriaDemoComponent,
      componentProps: {demo, title},
      cssClass: 'modal-class'
    });
    await modal.present();
  }

  // ================================
  // Toasts
  // ================================

  // Toast Basico
  // ================================
  async toastBasic(mensaje: string) {
    const toast = await this.toastC.create({
      position: 'middle',
      message: mensaje,
      cssClass: 'toast-class',
      duration: 1500
    });
    await toast.present();
  }

}
