import { Component, OnInit } from '@angular/core';
import { UserIService } from '../../../services/user-i.service';
import { AutorService } from '../../../services/autor.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-autor-lista',
  templateUrl: './autor-lista.component.html',
  styleUrls: ['./autor-lista.component.scss'],
})
export class AutorListaComponent implements OnInit {

  autores: any;
  Autores = [];

  profesion = 'todos';

  mediaUrl = environment.mediaUrl;

  cargando = true;

  constructor(
    private UIS: UserIService,
    private autoresS: AutorService
  ) {}

  ngOnInit() {
    this.Autores = this.autoresS.obetenerAutores();
    for (const autor of this.Autores) {
      if (autor.nombre === 'Varios') {
        this.Autores.splice(this.Autores.indexOf(autor),1);
      }
    }
    this.generarAutores();
  }

  generarAutores() {
    this.autores = this.Autores;
    if (this.profesion === 'todos') {
      this.reagruparAutores();
      return;
    }

    const newAutores = this.autores.filter((autor: any) => {
      if (autor.profesion === this.profesion) {
        return true;
      } else {
        return false;
      }
    });

    this.autores = newAutores;
    this.reagruparAutores();
  }

  reagruparAutores() {
    this.autores.sort((a, b) => {
      if (a.nombre < b.nombre) {
        return -1;
      } else if (a.nombre > b.nombre) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  verDetalles(o) {
    this.UIS.modalAutor(o);
  }

}
