import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController, IonTextarea } from '@ionic/angular';
import { links } from 'src/environments/environment';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
})
export class ContactoComponent implements OnInit, AfterViewInit {

  @ViewChild(IonTextarea, {static: true}) textarea: IonTextarea;

  correo = {
    asunto: '',
    mensaje: '',
    telefono: ''
  };

  links = links;

  constructor(
    public modalC: ModalController,
    private utilities: UtilitiesService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const element = document.getElementById('contacto-content');
    this.utilities.styleScrollbars(element);
  }

  mandarCorreo() {
    const body = `Telefono: ${this.correo.telefono}%0D%0A%0D%0A${this.correo.mensaje.split(/\n/).join('%0D%0A')}%0D%0A`;
    window.open(`mailto:mundogilda@gmail.com?subject=${this.correo.asunto}&body=${body}`);
  }

  abrirRed(red) {
    window.open(links[red], '_blank');
  }

}
