import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { AutorService } from '../../../services/autor.service';
import { UserIService } from '../../../services/user-i.service';

@Component({
  selector: 'app-autor-detalle',
  templateUrl: './autor-detalle.component.html',
  styleUrls: ['./autor-detalle.component.scss'],
})
export class AutorDetalleComponent implements OnInit {

  @Input() autor;

  libros = [];
  publicados = [];
  mediaUrl = environment.mediaUrl;

  constructor(
    public modalC: ModalController,
    private autorS: AutorService,
    private uiS: UserIService
  ) {}

  ngOnInit() {
    if (this.autor.librosNP) {
      this.publicados = JSON.parse(this.autor.librosNP) || [];
    }
    this.libros = this.autorS.librosDeAutor(this.autor.id);
  }

  abrirLibro(libro) {
    this.uiS.modalLibroDetalle(libro);
  }

}
