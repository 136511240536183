import { Component, OnInit, Input } from '@angular/core';
import { UserIService } from '../../../services/user-i.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {

  @Input() page: string;

  constructor(
    private uiS: UserIService,
    private router: Router,
    private popC: PopoverController
  ) {}

  ngOnInit() {}

  abrirContacto() {
    void this.popC.dismiss();
    this.uiS.modalContacto();
  }

  navegar(url) {
    void this.popC.dismiss();
    this.router.navigateByUrl(url);
    this.popC.dismiss();
  }

}
