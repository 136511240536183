import { Component, OnInit } from '@angular/core';
import { Libro } from 'src/app/interface/libro.interface';
import { environment } from 'src/environments/environment';
import { LibrosService } from '../../../services/libros.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { AutorService } from '../../../services/autor.service';

@Component({
  selector: 'app-opinion-list',
  templateUrl: './opinion-list.component.html',
  styleUrls: ['./opinion-list.component.scss'],
})
export class OpinionListComponent implements OnInit {
  libros: Libro[] = [];
  librosT: Libro[] = [];
  page = 1;
  pages: number = 1;
  search: string;
  onSearch: boolean;

  mediaUrl = environment.mediaUrl;

  constructor(
    private librosS: LibrosService,
    private utilities: UtilitiesService,
    private autorS: AutorService
  ) {}

  // Life Cycle
  // ================================

  ngOnInit(): void {
    const books = this.librosS.libros.map((o) => {
      try {
        // @ts-ignore
        if (o['reseñas2']) {
          const libro = { ...o, vista: false };
          libro.vista = false;
          if (libro.reseñas && libro.reseñas.length) {
            return libro;
          }
        } else {
          const libro = { ...o, reseñas: JSON.parse(o.reseñas) };
          libro.vista = false;
          if (libro.reseñas && libro.reseñas.length) {
            return libro;
          }
        }
      } catch (error) {
        console.error(error);
        console.log(o);
      }
    });
    this.librosT = books.filter((o) => o?.reseñas?.length);
    this.librosT = this.utilities.sortArray(this.librosT, 'publicado', 'desc');
    this.librosT = this.librosT.map((o) => {
      const autor = this.autorS.obtenerAutorPorId(o.autor);
      return { ...o, autor };
    })
    this.pages = Math.ceil(this.librosT.length / 20);
    this.obtenerLibros(1);
  }

  // Public functions
  // ================================

  obtenerLibros(page: number): void {
    this.onSearch = false;
    this.page = page;
    this.libros = this.librosT.slice(20 * (page - 1), 20 * page);
  }

  buscarLibros(): void {
    if (this.search.length < 3) {
      return;
    }
    this.onSearch = true;
    const exp = RegExp(this.search.toLocaleLowerCase());
    this.libros = this.librosT.filter((o) => exp.test(o.titulo.toLocaleLowerCase()));
  }
}
