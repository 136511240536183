import { Component, OnInit } from '@angular/core';
import { LibrosService } from '../../../services/libros.service';
import { environment } from '../../../../environments/environment';
import { UserIService } from '../../../services/user-i.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-ul-obras',
  templateUrl: './home-ul-obras.component.html',
  styleUrls: ['./home-ul-obras.component.scss'],
})
export class HomeUlObrasComponent implements OnInit {

  libros = [];
  mediaUrl = environment.mediaUrl;

  cargando = true;

  constructor(
    private librosS: LibrosService,
    private uiS: UserIService,
    public router: Router
  ) {}

  ngOnInit() {
    this.libros = this.librosS.obtenerLibrosTodos();
    if (this.libros.length > 6) {
      const cut = this.libros.length - 6;
      this.libros = this.libros.slice(0, this.libros.length - cut);
    }
  }

  verDetalle(o) {
    this.uiS.modalLibroDetalle(o);
  }

}
