import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './basic/header/header.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NavMenuComponent } from './basic/nav-menu/nav-menu.component';
import { HomeUlObrasComponent } from './home/home-ul-obras/home-ul-obras.component';
import { HomeServicesComponent } from './home/home-services/home-services.component';
import { FooterComponent } from './basic/footer/footer.component';
import { HomeEventosComponent } from './home/home-eventos/home-eventos.component';
import { AutorListaComponent } from './autor/autor-lista/autor-lista.component';
import { AutorDetalleComponent } from './autor/autor-detalle/autor-detalle.component';
import { DistribuidorPageComponent } from './distribuidor/distribuidor-page/distribuidor-page.component';
import { DistrbuidorListaComponent } from './distribuidor/distrbuidor-lista/distrbuidor-lista.component';
import { LibreriaLibrosComponent } from './libreria/libreria-libros/libreria-libros.component';
import { LibreriaDetalleComponent } from './libreria/libreria-detalle/libreria-detalle.component';
import { PipesModule } from '../pipes/pipes.module';
import { ContactoComponent } from './basic/contacto/contacto.component';
import { ServiciosPageComponent } from './servicios/servicios-page/servicios-page.component';
import { ModalPoliticasComponent } from './basic/modal-politicas/modal-politicas.component';
import { LibreriaDemoComponent } from './libreria/libreria-demo/libreria-demo.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LibreriaEbooksComponent } from './libreria/libreria-ebooks/libreria-ebooks.component';
import { HomeNewsComponent } from './home/home-news/home-news.component';
import { OpinionListComponent } from './opinion/opinion-list/opinion-list.component';

@NgModule({
  declarations: [
    ContactoComponent,
    HeaderComponent,
    FooterComponent,
    NavMenuComponent,
    HomeUlObrasComponent,
    HomeServicesComponent,
    HomeEventosComponent,
    AutorListaComponent,
    AutorDetalleComponent,
    DistribuidorPageComponent,
    DistrbuidorListaComponent,
    LibreriaLibrosComponent,
    LibreriaDetalleComponent,
    ServiciosPageComponent,
    ModalPoliticasComponent,
    LibreriaDemoComponent,
    LibreriaEbooksComponent,
    HomeNewsComponent,
    OpinionListComponent,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    HomeUlObrasComponent,
    HomeServicesComponent,
    HomeEventosComponent,
    AutorListaComponent,
    DistribuidorPageComponent,
    DistrbuidorListaComponent,
    LibreriaLibrosComponent,
    ServiciosPageComponent,
    OpinionListComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    PipesModule,
    PdfViewerModule
  ]
})
export class ComponentsModule { }
