import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-libreria-ebooks',
  templateUrl: './libreria-ebooks.component.html',
  styleUrls: ['./libreria-ebooks.component.scss'],
})
export class LibreriaEbooksComponent implements OnInit {

  @Input() ebooks: any[];

  constructor() {}

  ngOnInit() {}

  abrir(link) {
    window.open(link, '_blank');
  }

}
