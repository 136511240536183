import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home-services',
  templateUrl: './home-services.component.html',
  styleUrls: ['./home-services.component.scss'],
})
export class HomeServicesComponent implements OnInit {

  // @ts-ignore
  servicios = require('../../../../assets/json/services.json');

  env = environment;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {}

  servicio(id) {
    this.env.service = id;
    this.router.navigateByUrl('/servicios');
  }

}
