import { Injectable } from '@angular/core';
import { Distribuidor } from '../interface/distribuidor.interface';

@Injectable({
  providedIn: 'root'
})
export class DistribuidorService {
  // @ts-ignore
  distribuidores = require('../../assets/json/providers.json') as Distribuidor[];

  constructor() {}

  obtenerDistribuidores(): Distribuidor[] {
    return this.distribuidores;
  }

}
