import { Component, OnInit } from '@angular/core';
import { links } from '../../../../environments/environment';
import { UserIService } from '../../../services/user-i.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  links = links;

  constructor(
    private uiS: UserIService
  ) {}

  ngOnInit() {}

  open(red: string) {
    window.open(links[red], '_blank');
  }

  verPoliticas() {
    this.uiS.modalPoliticas();
  }

}
