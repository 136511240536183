import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserIService } from '../../../services/user-i.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AutorService } from '../../../services/autor.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-libreria-detalle',
  templateUrl: './libreria-detalle.component.html',
  styleUrls: ['./libreria-detalle.component.scss'],
})
export class LibreriaDetalleComponent implements OnInit {

  @Input() libro;

  mediaUrl = environment.mediaUrl;

  resenas = [];
  ebooks = [];
  publicado: Date;

  login = false;

  constructor(
    public modalC: ModalController,
    public uiS: UserIService,
    private router: Router,
    private autoresS: AutorService,
    private domSan: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.libro.reseñas && this.libro.reseñas !== 'null') {
      this.resenas = JSON.parse(this.libro.reseñas);
    }
    if (this.libro.ebooks && this.libro.ebooks !== 'null') {
      this.ebooks = JSON.parse(this.libro.ebooks);
    }
    if (typeof this.libro.autor === 'number') {
      this.libro.autor = this.autoresS.obtenerAutorPorId(this.libro.autor);
    }
    if (this.libro.autor?.foto) {
      const foto = this.libro.autor.foto as string;
      if (foto.substring(0, 5) !== 'https') {
        this.libro.autor.foto = `${this.mediaUrl}/${this.libro.autor?.foto}`;
      }
    }
    this.publicado = new Date(this.libro.publicado);
  }

  probar() {
    this.uiS.modalDemo(this.libro.demo, this.libro.titulo);
  }

  verAutores() {
    this.router.navigateByUrl('/autores');
    this.modalC.dismiss();
  }

}
