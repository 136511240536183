import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: []
  },
  {
    path: 'autores',
    loadChildren: () => import('./pages/autores/autores.module').then( m => m.AutoresPageModule),
    canActivate: []
  },
  {
    path: 'distribuidores',
    loadChildren: () => import('./pages/distribuidores/distribuidores.module').then( m => m.DistribuidoresPageModule),
    canActivate: []
  },
  {
    path: 'libreria',
    loadChildren: () => import('./pages/libreria/libreria.module').then( m => m.LibreriaPageModule),
    canActivate: []
  },
  {
    path: 'libreria/:nombre',
    loadChildren: () => import('./pages/libreria/libreria.module').then( m => m.LibreriaPageModule),
    canActivate: []
  },
  {
    path: 'servicios',
    loadChildren: () => import('./pages/servicios/servicios.module').then( m => m.ServiciosPageModule),
    canActivate: []
  },
  {
    path: 'opinion',
    loadChildren: () => import('./pages/opinion/opinion.module').then( m => m.OpinionPageModule)
  },
  {
    path: '**',
    redirectTo: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
