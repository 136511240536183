import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { UserIService } from '../../../services/user-i.service';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() page: string;

  usuario = null;

  constructor(
    public router: Router,
    private alertC: AlertController,
    private uiS: UserIService,
    private popC: PopoverController
  ) {}

  ngOnInit() {}

  home() {
    this.router.navigateByUrl('/');
  }

  abrirContacto() {
    this.uiS.modalContacto();
  }

  async buscarLibro() {
    const alert = await this.alertC.create({
      header: '¿Que libro desea buscar?',
      cssClass: 'alert-class',
      inputs: [{
        placeholder: 'Libro a buscar',
        cssClass: 'texto',
        name: 'busqueda'
      }],
      buttons: [{
        text: 'Cancelar',
        cssClass: 'alert-button-class',
        role: 'cancel'
      }, {
        text: 'Buscar',
        cssClass: 'alert-button-class',
        handler: (data) => {
          this.router.navigateByUrl('/libreria/' + data.busqueda);
        }
      }]
    });
    await alert.present();
  }

  async openNavPop(event: Event): Promise<void> {
    const pop = await this.popC.create({
      component: NavMenuComponent,
      mode: 'ios',
      event,
      componentProps: {
        page: this.page,
      }
    });
    await pop.present();
  }
}
