import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-distrbuidor-lista',
  templateUrl: './distrbuidor-lista.component.html',
  styleUrls: ['./distrbuidor-lista.component.scss'],
})
export class DistrbuidorListaComponent implements OnInit {

  @Input() distribuidores;
  @Input() ciudad;

  mediaUrl = environment.mediaUrl;

  constructor() { }

  ngOnInit() {}

  abrirLink(url) {
    window.open(url, '_blank');
  }

}
