import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserIService } from '../../../services/user-i.service';

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss'],
})
export class HomeNewsComponent implements OnInit {

  ebook1 = [{
    proveedor: 'Amazon Kindle',
    link: 'https://www.amazon.com.mx/Ananke-Bodies-English-Gilda-Salinas-ebook/dp/B08LNXZRM6/ref=sr_1_2?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=ananke&qid=1608239117&s=digital-text&sr=1-2'
  }, {
    proveedor: 'Kobo',
    link: 'https://www.kobo.com/mx/es/ebook/ananke-4'
  }, {
    proveedor: 'bol',
    link: 'https://www.bol.com/nl/p/ananke/9300000015070021/?bltgh=i9VuAAa4dvrfuVqo9cRBqA.1_4.5.ProductTitle'
  }, {
    proveedor: 'weltbild',
    link: 'https://www.weltbild.de/artikel/ebook/ananke_33457536-1'
  }];

  ebook2 = [{
    proveedor: 'Amazon Kindle',
    link: 'https://www.amazon.com.mx/Gardens-Dont-Grow-Rivers-English-ebook/dp/B08LHJ8MRZ/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Gardens+don%27t+grow&qid=1608239008&s=digital-text&sr=1-1'
  }, {
    proveedor: 'Kobo',
    link: 'https://www.kobo.com/mx/es/ebook/gardens-don-t-grow-in-rivers'
  }, {
    proveedor: 'bol',
    link: 'https://www.bol.com/nl/p/gardens-don-t-grow-in-rivers/9300000014878554/?bltgh=kVc8uPJePeEisxU1NANL3g.1_4.5.ProductTitle'
  }, {
    proveedor: 'weltbild',
    link: 'https://www.weltbild.de/artikel/ebook/gardens-dont-grow-in-rivers_33435533-1'
  }];

  constructor(
    public modalC: ModalController,
    public uiS: UserIService
  ) {}

  ngOnInit() {}

}
