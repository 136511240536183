import { Component, OnInit, ViewChild, ViewChildren, ElementRef, Output, EventEmitter } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { UserIService } from '../../../services/user-i.service';

@Component({
  selector: 'app-servicios-page',
  templateUrl: './servicios-page.component.html',
  styleUrls: ['./servicios-page.component.scss'],
})
export class ServiciosPageComponent implements OnInit {

  @Output() scrollto = new EventEmitter();

  // @ts-ignore
  servicios = require('../../../../assets/json/services.json');

  ver = {
    1: {
      ver: false,
      icon: 'arrow-down-circle'
    },
    2: {
      ver: false,
      icon: 'arrow-down-circle'
    },
    3: {
      ver: false,
      icon: 'arrow-down-circle'
    },
    4: {
      ver: false,
      icon: 'arrow-down-circle'
    },
    5: {
      ver: false,
      icon: 'arrow-down-circle'
    },
    6: {
      ver: false,
      icon: 'arrow-down-circle'
    },
  };

  constructor(
    private route: ActivatedRoute,
    private uiS: UserIService
  ) {
    this.route.params.subscribe(async () => {
      if (environment.service) {
        await Object.keys(this.ver).forEach(key => {
          this.ver[key].ver = false;
          this.ver[key].icon = 'arrow-down-circle';
        });

        this.abrir(environment.service);
        this.goTo(environment.service);
      }
    });
  }

  ngOnInit() {}

  goTo(id) {
    setTimeout(() => {
      const x = document.getElementById(id).offsetTop;
      this.scrollto.emit(x);
    }, 500);
  }

  abrir(id) {
    if (this.ver[id].ver) {
      this.ver[id].ver = false;
      this.ver[id].icon = 'arrow-down-circle';
    } else {
      this.ver[id].ver = true;
      this.ver[id].icon = 'arrow-up-circle';
    }
  }

  contacto() {
    this.uiS.modalContacto();
  }

}
