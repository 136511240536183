import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-libreria-demo',
  templateUrl: './libreria-demo.component.html',
  styleUrls: ['./libreria-demo.component.scss'],
})
export class LibreriaDemoComponent implements OnInit {

  // Variable que manipula al componente que interpreta el PDF
  @ViewChild(PdfViewerComponent) private PDF: PdfViewerComponent;

  mediaUrl = environment.mediaUrl;

  @Input() demo;
  @Input() title: string;
  src: string;
  rotation = 0;
  TotalPages: number;
  ActualPage = 1;
  zoom = 1;

  constructor(
    public modalC: ModalController
  ) {}

  ngOnInit() {}

  // Funcion que aumenta o disminuye el zoom del documento
  Zoom(aumento) {

    // En dado caso que el aumento sea negativo (osease reducción) y el zoom actual ya se encuentre por debajo de 0.3
    // no se le permite reducir mas el zoom pues si entra en numeros negativos puede generar un error
    if (!(aumento < 0 && this.zoom < 0.3)) {
      this.zoom += aumento;
    }
  }

  PdfCargado(evento) {

    // Establecemos el total de paginas del documento
    this.TotalPages = evento._pdfInfo.numPages;

    // Establecemos el zoom predefinido que es "1"
    this.zoom = this.PDF.zoom;

    // nos suscribimos al evento de cambio de pagina, asi sabremos en que pagina estamos mientras navegamos sobre el documento
    this.PDF.pageChange.subscribe( page => {
      this.ActualPage = page;
    });
  }

}
