import { Injectable } from '@angular/core';
import { Libro } from '../interface/libro.interface';
import { Autor } from '../interface/autor.interface';
import { AutorService } from './autor.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class LibrosService {
  // @ts-ignore
  libros = require('../../assets/json/books.json') as Libro[];
  // @ts-ignore
  autores = require('../../assets/json/autors.json') as Autor[];
  pages: number;
  searchPages: number;

  constructor(private autorS: AutorService, private utilities: UtilitiesService) {
    this.pages = Math.ceil(this.libros.length / 20);
    this.libros = this.utilities.sortArray(this.libros, 'publicado', 'desc');
  }

  obtenerLibros(page: number): Libro[] {
    const libros: Libro[] = [];
    for (const libro of this.libros.slice(20 * (page - 1), 20 * page)) {
      libros.push({
        ...libro,
        autor: this.autorS.obtenerAutorPorId(libro.autor),
      });
    }
    return libros;
  }

  buscarLibros(search: string, page: number): Libro[] {
    const libros: Libro[] = [];
    const exp = RegExp(search.toLocaleLowerCase());
    const busqueda = this.libros.filter((o) => exp.test(o.titulo.toLocaleLowerCase()));
    this.searchPages = Math.ceil(busqueda.length / 20);
    for (const libro of busqueda.slice(20 * (page - 1), 20 * page)) {
      libros.push({
        ...libro,
        autor: this.autorS.obtenerAutorPorId(libro.autor),
      });
    }
    return libros;
  }

  obtenerLibrosTodos(): Libro[] {
    const libros: Libro[] = [];
    for (const libro of this.libros) {
      libros.push({
        ...libro,
        autor: this.autorS.obtenerAutorPorId(libro.autor),
      });
    }
    return this.libros;
  }

}
