import { Component, OnInit } from '@angular/core';
import { DistribuidorService } from '../../../services/distribuidor.service';

@Component({
  selector: 'app-distribuidor-page',
  templateUrl: './distribuidor-page.component.html',
  styleUrls: ['./distribuidor-page.component.scss'],
})
export class DistribuidorPageComponent implements OnInit {

  distribuidores = [];

  agrupador: any = {};
  keys: any = [];

  cargando = true;

  constructor(
    private distribuidoresS: DistribuidorService
  ) {}

  ngOnInit() {
    this.distribuidores = this.distribuidoresS.obtenerDistribuidores();
    this.distribuidores.sort((a, b) => {
      if (a.nombre > b.nombre) {
        return 1;
      } else if (a.nombre < b.nombre) {
        return -1;
      } else {
        return 0;
      }
    });
    this.agruparPorCiudad();
  }

  async agruparPorCiudad() {
    await this.distribuidores.forEach(o => {
      if (!o.ciudad) {
        return;
      }
      if (!this.agrupador[o.ciudad]) {
        this.agrupador[o.ciudad] = [];
      }

      this.agrupador[o.ciudad].push(o);
    });
    this.keys = Object.keys(this.agrupador);
  }

}
